.root {
  padding: var(--mantine-spacing-lg);
}

.heading {
  text-align: center;
}

.main {
  display: flex;
  gap: var(--mantine-spacing-lg);
}


.bodySection {
  width: 75%;
}


.conclusionBody {
  margin-top: var(--mantine-spacing-lg);
  margin-bottom: var(--mantine-spacing-lg);

  p {
    padding: var(--mantine-spacing-xs);

  }

}

.bodyContainer {
  width: 75%;
}

.mainSection {
  display: flex;
  padding: var(--mantine-spacing-sm);
  justify-content: space-between;
}


.bodyHeading {
  border-bottom: 1px solid var(--mantine-color-gray-2);
  margin-bottom: var(--mantine-spacing-xs);  
}

.border {
  width: 1px;
  border-left: 1px solid var(--mantine-color-gray-2);
  margin-top: var(--mantine-spacing-lg);
}

.timelineContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: var(--mantine-spacing-lg);
  margin-bottom: var(--mantine-spacing-lg);

  p {
    padding: var(--mantine-spacing-xs);

  }
}

.timeline {
  display: flex;
  flex-direction: column;
  margin-top: var(--mantine-spacing-lg);
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}