
.root {
  position: absolute;
  color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));

  @media (max-width: $mantine-breakpoint-sm) {
    display: none;
  }
}

