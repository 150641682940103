
.navbar {
  @media print {
    display: none;
  }
}

.main {
  @media print {
    padding-left: 0;   
    padding-top: 0; 
  }
}