
.root {
  cursor: pointer;  
}

.date {
  white-space: nowrap;
}

.acctName {
  white-space: nowrap;
}


.contactName {
  white-space: nowrap;
}