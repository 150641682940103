

.wrapper {
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,249,250,1) 50%);  
  position: relative;
}

.inner {
  position: relative;
  z-index: 1;
}

.headlinesWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
    gap: var(--mantine-spacing-xl);
  }

}

.iconContainer {
  position: relative;
}

.pulse {
  position: absolute;
  -webkit-animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
  animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
  /* animation: rotate-scale-up 2s linear infinite; */
}

.pulseIcon {  
  box-shadow: 0 0 0 0 rgb(55, 178, 77);
  margin: 10px;  
	transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0  rgba(55, 178, 77, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 20px rgba(55, 178, 77, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(55, 178, 77, 0);
	}
}

@-webkit-keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
}
@keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
}


@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
            transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
            transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
  }
}
