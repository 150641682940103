

.root {
  padding-top: var(--mantine-spacing-xs);  
}

.subheadingItem {
  padding: rem(7px) var(--mantine-spacing-xs);
  background-color: var(--mantine-color-gray-1);
  font-size: var(--mantine-font-size-md);
  /* text-transform: uppercase; */
  p { 
    font-weight: 500; 
    color: var(--mantine-color-gray-8);
  }
  border-bottom: 1px solid var(--mantine-color-gray-2);
}
