
.root {
  display: flex;
  align-items: center;
  font-size: var(--mantine-font-size-sm);
  border-bottom: 1px solid var(--mantine-color-gray-2); 
  gap: var(--mantine-spacing-xl);
  padding: rem(7px) var(--mantine-spacing-xs);

  &:hover {
    cursor: pointer;
    background-color: var(--mantine-color-gray-1);
  }  

  
}

.conclusionTypeLabel {
  /* font-weight: 500; */
  font-size: var(--mantine-font-size-md);  
  white-space: nowrap;
}

.conclusionBody {
  flex: 1; 
}

.conclusionTimestamp {
  text-align: right;
  white-space: nowrap;
}