

.unreadMessages {
  white-space: nowrap;
}

.descriptor {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* max-width: 200px; */
}