.root {  
  padding-right: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-xs) {
    padding-right: var(--mantine-spacing-sm);
  }
}

.root a, .root p {  
  @media (max-width: $mantine-breakpoint-xs) {
    font-size: var(--mantine-font-size-sm);
  }
}