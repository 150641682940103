
.xeroRecordPaper {
  padding: var(--mantine-spacing-lg);
}

.xeroRecordTitle {
  margin-bottom: var(--mantine-spacing-lg);
}

.xeroRecordTable {
  padding: 0;
}

.xeroRecordGrid {
  font-size: var(--mantine-font-size-sm);  
}

.xeroRecordStack { 
  flex: 1;
  gap: var(--mantine-spacing-xs);
  
  & + & {
    padding-left: var(--mantine-spacing-xl);
    margin-left: var(--mantine-spacing-xl);
    border-left: rem(1px) solid var(--mantine-color-gray-3);

    @media (max-width: $mantine-breakpoint-sm) {
      padding-left: 0;
      margin-left: 0;
      border-left: 0;
      padding-top: var(--mantine-spacing-xl);
      margin-top: var(--mantine-spacing-xl);
      border-top: rem(1px) solid var(--mantine-color-gray-3);
    }
  }
}

.xeroRecordGroup {
  justify-content: space-between;  
  align-items: baseline;  
  /* gap: var(--mantine-spacing-lg); */
}

.xeroRecordLabel {  
  font-weight: 700;
  /* width: 200px; */
  color: var(--mantine-color-dimmed);
}

.xeroRecordValue {
  flex-grow: 1;
  text-align: left;
  &:empty {
    color: var(--mantine-color-dimmed);
    &:after {
      content: "Not on record";
    }
  }
  display: flex;
  gap: var(--mantine-spacing-xs);
}

.xeroRecordPhoneLine {
  display: flex;
  align-items: center;
  gap: 5px;
}

.xeroRecordPhoneIcon {
  width: 0.8rem;
  height: 0.8rem;
  translate: 0 2px;
  stroke-width: 1;
}

.lineItemTable {
  tr {
    border-bottom-color: var(--mantine-color-gray-2);
  }    
  thead {
    tr {
      border-bottom-color: var(--mantine-color-gray-2);
    }    
  }
  th {
    color: var(--mantine-color-dimmed);
    padding-left: 0;
  }
  td {
    padding-left: 0;
  }

  .totalsRow {    
    border-bottom: 0;
    font-weight: 500;        
    /* td {
      padding-top: 5px;
      padding-bottom: 0;      
    }     */
    /* :nth-last-child(2) {
      text-align: right;
    } */
    /* :nth-last-child(2), :nth-last-child(1) {
      border-bottom: 1px var(--mantine-color-gray-2) solid;
    } */
  }
}