.companyDetailRoot {
  display: flex;
  flex-wrap: wrap;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }


  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column-reverse;
  }
}

.matchedCompanyDetailMain {
  flex: 1;
  padding: var(--mantine-spacing-md);
  border: 1px solid var(--mantine-color-gray-1);
  border-bottom-left-radius: var(--mantine-radius-md);
  border-top: none;
  display: flex;
  flex-direction: column;
}

.uncertainCompanyDetailMain {  
  padding: var(--mantine-spacing-xs) 0;
  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0;
  }
}

.matchedCompanyDetailTitle {
  margin-bottom: var(--mantine-spacing-lg);
  display: flex;
  justify-content: space-between;
}

.matchedCompanyDetailSide {
  padding: var(--mantine-spacing-md);
  max-width: 25%;
  border: 1px solid var(--mantine-color-gray-1);
  background: var(--mantine-color-gray-0);
  /* border-bottom-left-radius: var(--mantine-radius-md); */
  border-bottom-right-radius: var(--mantine-radius-md);
  border-top: none;
  border-left: none;

  color: var(--mantine-color-gray-7);

  /* background-image: linear-gradient(-60deg,
    var(--mantine-color-blue-0) 0%,
    var(--mantine-color-blue-1) 100%); */

  display: flex;
  flex-direction: column;
  justify-content: center;


  @media (max-width: $mantine-breakpoint-sm) {
    max-width: none;
  }

}

.noMatchCompanyDetailSide {
  max-width: none;
  flex: 1;
}

.matchedCompanyDetailSideIconContainer {
  margin-bottom: var(--mantine-spacing-lg);
  margin-top: var(--mantine-spacing-lg);
}

.matchedCompanyDetailSideIcon {
  color: var(--mantine-color-green-4);
  width: rem(48px);
  height: rem(48px);
  stroke-width: 1;
}

.uncertainCompanyDetailSideIcon {
  color: var(--mantine-color-orange-4);
  width: rem(48px);
  height: rem(48px);
  stroke-width: 1;
}


.matchedCompanyDetailSideLabel {
  text-align: center;
  /* color: var(--mantine-color-gray-7); */
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
  margin-top: rem(3px);

  & a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}

.noMatchCompanyDetailSideIcon {
  color: var(--mantine-color-gray-4);
  width: rem(48px);
  height: rem(48px);
}

.uncertainCompanyDetailTable {
  font-size: var(--mantine-font-size-sm);
  

  a:hover {
    svg {
      stroke: var(--mantine-color-blue-7);
    }    
  }
  

  @media (max-width: $mantine-breakpoint-sm) {
    display: block;

    th {
      display: none;
    }

    tr {
      background-color: inherit;
      display: flex;
      flex-wrap: wrap;
      border-top: rem(1px) solid var(--table-border-color);      
      padding: var(--mantine-spacing-xs) 0;
    }

    td {
      display: block;
      padding: var(--mantine-spacing-xs);
    }

    .uncertainCompanyDetailTableAddress {
      width: 100%;
    }

  }

}