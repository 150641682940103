
.root {
  cursor: pointer;  
}

.date {
  white-space: nowrap;
}

.contactName {
  white-space: nowrap;
}