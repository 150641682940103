.root {
  color: var(--mantine-color-white);
  background-image: linear-gradient(
    -60deg,
    var(--mantine-color-blue-4) 0%,
    var(--mantine-color-blue-7) 100%
  );
  padding: calc(var(--mantine-spacing-xl) * 1.5);
  padding-top: var(--mantine-spacing-xl);
  border-radius: var(--mantine-radius-md);
  /* max-width: 75%; */

}


.statsRoot {  
  display: flex;  
  flex-direction: column;
  gap: var(--mantine-spacing-xl);
}

.statsRow {
  
  display: flex;  
  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
}

.title {
  color: var(--mantine-color-white);
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--mantine-font-size-sm);
}

.count {
  color: var(--mantine-color-white);
  font-size: rem(32px);
  line-height: 1;
  font-weight: 700;
  margin-bottom: var(--mantine-spacing-md);
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
}

.description {
  color: var(--mantine-color-blue-0);
  font-size: var(--mantine-font-size-sm);
  margin-top: rem(5px);
}

.stat {
  flex: 1;

  & + & {
    padding-left: var(--mantine-spacing-xl);
    margin-left: var(--mantine-spacing-xl);
    border-left: rem(1px) solid var(--mantine-color-blue-3);

    @media (max-width: $mantine-breakpoint-sm) {
      padding-left: 0;
      margin-left: 0;
      border-left: 0;
      padding-top: var(--mantine-spacing-xl);
      margin-top: var(--mantine-spacing-xl);
      border-top: rem(1px) solid var(--mantine-color-blue-3);
    }
  }
}