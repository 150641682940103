
.root {

  display: flex;
  align-items: center;
  font-size: var(--mantine-font-size-sm);
  border-bottom: 1px solid var(--mantine-color-gray-2);
  gap: var(--mantine-spacing-xl);
  padding: rem(13px) var(--mantine-spacing-xs);

  &:hover {
    cursor: pointer;
    background-color: var(--mantine-color-blue-0) !important;
    /* border-bottom: 2px solid var(--mantine-color-blue-2); */
  }

  &:not([data-unread]) {
    background-color: #f1f7ff;
  }

}

.iconContainer {
  width: 64px;
}

.heading {
  white-space: nowrap;

  &[data-unread] {
    font-weight: var(--mantine-h4-font-weight);
  }
}

.preheadingContainer {
  flex: 1;
}

.preheading {
  &:not([data-unread]) {
    color: var(--mantine-color-dimmed);
  }
}

.date {
  white-space: nowrap;

  &[data-unread] {
    font-weight: var(--mantine-h4-font-weight);
  }
}