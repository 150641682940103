

.modalContent {
  flex: 0 0 1000px;
  /* flex: 1; */
  /* max-width: 600px; */
  overflow: hidden;    
  display: flex;
}

.root {
  padding: var(--mantine-spacing-lg);  
  display: flex;
  flex-direction: column;
  flex: 1;
}

.top {
  text-align: left;
  padding: 0 var(--mantine-spacing-lg);
}

.bottom {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  padding: var(--mantine-spacing-lg);
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.bodyHeading {
  padding: var(--mantine-spacing-xs);
  border-bottom: 1px solid var(--mantine-color-gray-2);
  margin-top: var(--mantine-spacing-xs);  
  /* margin-bottom: var(--mantine-spacing-xs);   */
}

.bodyContent {  
  flex: 1;
  /* overflow: auto; */
  overflow: hidden;
  /* padding-top: var(--mantine-spacing-lg); */
  /* padding-left: var(--mantine-spacing-xs); */
  /* padding-right: var(--mantine-spacing-lg); */

  a {
    font-weight: inherit;
  }

  ul, ol {
    max-width: 90%;
  }
  
  h1, h2, h3, h4, p, li {    
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-xs);
    padding-right: var(--mantine-spacing-lg);
  }
}

.border {
  width: 1px;
  border-left: 1px solid var(--mantine-color-gray-2);
  margin-top: var(--mantine-spacing-lg);
}

/* .right {
  display: flex;
  flex-direction: column;
} */

.timeline {

  @media (max-width: $mantine-breakpoint-md) {
    display: none;
  }

  /* display: flex; */
  /* flex-direction: column; */
  padding: var(--mantine-spacing-xl);
  /* margin-top: var(--mantine-spacing-lg); */
  /* justify-content: flex-start; */
  /* align-items: center; */
  /* flex: 1; */
}