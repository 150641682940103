.header {
  @media print {
    display: none;
  }
}

.printHeader {
  @media screen {
    display: none;
  }

  @media print {
    height: 20px;
    display: flex;
    flex-direction: row;
    padding-right: var(--mantine-spacing-xl);
    padding-left: var(--mantine-spacing-md);
    justify-content: space-between;
  }
}

/* 
.burger {
  @media print {
    display: none;
  }
}

.dropdownButtonSection {
  @media print {
    display: none !important;
  }
} */