.backAnchor {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  font-size: var(--mantine-font-size-sm);
  text-decoration: none;
  /* cursor: pointer; */
  color: var(--mantine-color-dimmed);

  &:hover {
    color: var(--mantine-color-black);
  }
}

.backAnchorIcon {
  height: 16px;
  width: 16px;
  margin-top: 3px;
}

