.wrapper {
  padding-top: rem(120px);
  padding-bottom: rem(80px);

  @media (max-width: $mantine-breakpoint-sm) {
    padding-top: rem(80px);
    padding-bottom: rem(60px);
  }
}



.title {
  text-align: center;
  font-weight: 800;
  font-size: rem(40px);
  letter-spacing: -1px;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  margin-bottom: var(--mantine-spacing-xs);
  font-family:
    Greycliff CF,
    var(--mantine-font-family);

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: rem(28px);
    /* text-align: left; */
  }
}

.highlight {
  /* color: light-dark(var(--mantine-color-blue-6), var(--mantine-color-blue-4)); */
  font-weight: 400 !important;
}

.description {
  text-align: center;

  @media (max-width: $mantine-breakpoint-xs) {
    text-align: left;
    font-size: var(--mantine-font-size-md);
  }
}

.controls {
  margin-top: var(--mantine-spacing-lg);
  display: flex;
  justify-content: center;

  @media (max-width: $mantine-breakpoint-xs) {
    flex-direction: column;
  }
}

.control {
  &:not(:first-of-type) {
    margin-left: var(--mantine-spacing-md);
  }

  @media (max-width: $mantine-breakpoint-xs) {
    height: rem(42px);
    font-size: var(--mantine-font-size-md);

    &:not(:first-of-type) {
      margin-top: var(--mantine-spacing-md);
      margin-left: 0;
    }
  }
}