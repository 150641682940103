.root {
  display: flex;
  align-items: center;
  padding: var(--mantine-spacing-sm);

  & + & {
    border-top: 1px solid var(--mantine-color-gray-2);
  }
}

.nameEmailRole {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: var(--mantine-spacing-md);
}

.connectedButton {
  cursor: inherit;
  &:hover {
    background-color: inherit;    
  }
}