.container {
  @media print {
    margin: 0;
    max-width: 100%;
  }
}

.criteriaControls {
  @media print {
    display: none;
  }
}


.datePickerInput:disabled {
  /* border: 0; */
  background-color: transparent;
  color: var(--mantine-color-black);
  opacity: 1;
}

.headlineStatArrow svg {
  transform: rotate(90deg);    
  @media (min-width: $mantine-breakpoint-sm) {
    transform: none;
  }
  @media print {
    transform: none;
  }
}

.breakdownStatArrow svg {
  transform: rotate(90deg);    
  @media (min-width: $mantine-breakpoint-md) {
    transform: none;
  }
  
  @media print {
    transform: none;
  }
}

.statCardStack {
  @media print {
    flex: 1 1 auto !important;
  }
}

.statCardContainer {
  @media print {
    flex-direction: row !important;
    gap: var(--mantine-spacing-xs) !important;
  }
}

.statCardContainerFullWidth {
  @media print {
    justify-content: space-between !important;
  }
}

.statCard {
  @media print {
    padding: var(--mantine-spacing-md);
    font-size: var(--mantine-font-size-sm);
  }
}

:global(.mantine-Card-root) {
  @media print {
    box-shadow: none;
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: 0 !important;    
  }  
}


:global(.mantine-Paper-root) {
  @media print {
    box-shadow: none;
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: 0 !important;
  }  
}

.chartContainer {
  @media print {
    flex-direction: row !important;
    & > * {
      flex: 1;
    }
  }
}

.statsTableContainer {
  @media print {
    flex-direction: row !important;    
  }
}

.statsTable {
  th {
    font-weight: normal;
    color: var(--mantine-color-dimmed);
  }

  td:first-child {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

}


.unbreakableContainer {
  break-inside: avoid;
}

.userActivityTable {
  td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}