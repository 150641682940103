
.statusRoot {
  background-image: linear-gradient(-60deg,
      var(--mantine-color-blue-4) 0%,
      var(--mantine-color-blue-7) 100%);
  color: var(--mantine-color-white);
 

}

.statusDivider {
  border-top-color: var(--mantine-color-blue-3);
}

.statusInner {
  padding-top: var(--mantine-spacing-md);
  display: flex;
  flex-wrap: wrap;
  color: var(--mantine-color-black);
  gap: var(--mantine-spacing-md);
  justify-content: center;
  /* @media (max-width: $mantine-breakpoint-md) {
    flex-direction: column;    
  } */
}

.statusCard {
  min-width: rem(200px);
  min-height: rem(140px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  

}

.statusCardIcon {  
  color: var(--mantine-color-green-7);
  width: rem(48px);
  height: rem(48px);
  translate: rem(3px) 0;  
  stroke-width: 1;
}

.statusCardStats {
  margin-top: rem(16px);
  text-align: center;
}

.statusCardStatusLabel {
  text-align: center;
  color: var(--mantine-color-green-7);
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
  margin-top: rem(3px);
  & a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
}

.statusCardIconContainer {
  margin-bottom: var(--mantine-spacing-lg);
  margin-top: var(--mantine-spacing-lg);
}

.statusCardTitleContainer {
  min-height: rem(41px);
}

.statusCardMainStat {
  font-weight: 700;
}

.statusCardTitle {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-gray-7);
  /* text-align: center; */
}

.statusCardStatSubtle {
  /* font-weight: normal; */
  color: var(--mantine-color-dimmed);
}

.statusCardStatsTable {
  display: flex;
  gap: var(--mantine-spacing-sm);
  >:first-child {
    text-align: right;
  }
  >:last-child {
    text-align: left;
  }
}