

.root {
  display: flex;
  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
}

.notProcessedWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.fieldsWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--mantine-spacing-lg);
  padding: var(--mantine-spacing-lg);
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
}

.fieldLabel {
  font-weight: 500;
  font-size: var(--mantine-font-size-md);  
}

.fieldValue {
  font-size: var(--mantine-font-size-md);    
}