.box {
  padding-top: var(--mantine-spacing-sm);
  border-top: rem(1) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
}

.user {
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-md);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));

  &[data-active] {
    background-color: var(--mantine-primary-color-light);
    color: var(--mantine-primary-color-light-color);
  }

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-8));
  }
}