.root {
  
  :global(.mantine-Input-wrapper) {
    margin-bottom: 0;
  }

  :global(.mantine-InputWrapper-error) {
    position: absolute;
    margin-top: rem(5);
  }

  margin-bottom: var(--mantine-spacing-lg);

}
