.card {
  background-color: var(--mantine-color-blue-filled);
}

.title {
  color: var(--mantine-color-white);
  /* opacity: 0.65; */
}

.stats {
  color: var(--mantine-color-white);
}

@keyframes progressSection {
  from   { left: -50%; }
  to { left: 100%; }
}

.progressSection {
  position: relative;
  background-color: var(--mantine-color-white);
  border-radius: var(--progress-radius);  
  animation-name: progressSection;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.progressTrack {
  background-color: var(--mantine-color-blue-3);
}